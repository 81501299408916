import { ReturnedIcon, TruckIcon, LockerDarkIcon, ReceivedPackagesIcon, BarcodeIcon, Airplane2Icon, Truck2Icon } from '../../assets/icons';
import InboxPaperDeclared from '../../assets/images/inboxPaperDeclared.png';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '../Button';
import SideBar from '../sidebar/SideBar';
import PackageDetails from './PackageDetails';
import Declaration from './Declaration';
import Resolution from '../hooks/resolution';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import apiRequest from '../ApiRequest';
import Modal from '../modal/Modal';
import { useTranslation } from 'react-i18next';
import i18n, { t } from 'i18next';
import useImage from '../hooks/useImage';
import GlobalContext from '../GlobalContext';
import HawbBranch from './HawbBranch';
import { branches } from '../../data/branches';
import { lockers } from '../../data/lockers';
import { getAwaitingAsyncThunk } from '../../store/thunks/packages.thunk';
import { useDispatch, useSelector } from 'react-redux';
import { getAmountWithCurrency } from '../../utils/utils';
import { getCustomerInfoAsyncThunk } from '../../store/thunks/customer.thunk';

export default function Flight({ packageStatus, item, keyProp, icon, isAwaiting, setShowLockerBarcode, setLockerBarcode }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentLang = i18n.language;

  // console.log(item);

  const [branchName, setBranchName] = useState([]);
  const [lockerData, setLockerData] = useState();
  const [openHawbBranch, setOpenHawbBranch] = useState(false);
  const packageRef = useRef();
  const { token, setAlert, setShowLoader, setHelperTracking, setShowHelperTracking } = useContext(GlobalContext);
  const queryClient = useQueryClient();
  const [country, setCountry] = useState(null);
  const branchSelection = useSelector((state) => state.customer.branchSelection);

  // const { data: getCustomerInfo } = useQuery('getCustomerInfo', () =>
  //   apiRequest({
  //     command: 'get_customer_info',
  //     sessionId: token
  //   })
  // );
  // useEffect(() => {
  //   console.log('Flight.js - get_customer_info');
  // }, []);

  // const branchSelection = getCustomerInfo?.data?.result?.branch_selection;
  const eachBranch = branchSelection?.filter((each) => each.hawb === item.hawb)[0];

  // console.log(item);

  useEffect(() => {
    if (eachBranch?.locker) {
      setLockerData(lockers.filter((address) => address.id === eachBranch.locker)[0]);
    }
  }, [eachBranch, lockers]);

  useEffect(() => {
    item?.trackings?.filter((tracking) => {
      let __country = item?.proxy_country ? item?.proxy_country : tracking?.country;
      if (__country === 'R' || __country === 'T') {
        __country = 'CHINA';
      }
      return setCountry(__country);
    });

    if (item.branch && branches) {
      const filteredBranch = branches?.filter((singleBranch) => singleBranch.id === item.branch);
      setBranchName(filteredBranch);
    }
    if (!item.branch && eachBranch?.branch) {
      const filteredBranch = branches?.filter((singleBranch) => singleBranch.id === eachBranch.branch);
      setBranchName(filteredBranch);
    }
  }, [item?.branch, branches, eachBranch?.branch]);

  useEffect(() => {
    if (country === 'R' || country === 'T') {
      setCountry('CHINA');
    }
  }, [country]);

  const documentNeededArray = item?.trackings?.filter((tracking) => tracking?.purchase_order_uploaded === '0');
  const comingTrackingArray = item?.trackings?.filter((tracking) => tracking?.notice_status === 'COMING');
  // ** Change Branch */
  const changeHawbBranch = useMutation(
    (params) => {
      return apiRequest(
        {
          command: 'set_hawb_branch_locker_request',
          sessionId: token
        },
        {
          hawb: item.hawb,
          branch: params.branch,
          lockerId: params.lockerId
        }
      );
    },
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          dispatch(getCustomerInfoAsyncThunk());
          setAlert(['success', t(response.data.message)]);
          setOpenHawbBranch(false);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );
  const refreshPending = async (branch, locker) => {
    try {
      await changeHawbBranch.mutateAsync({ branch: branch, lockerId: locker });
      queryClient.invalidateQueries('getPending');
      queryClient.invalidateQueries('getCustomerInfo');
    } catch (error) {
      console.log('error', error);
    }
  };

  const getLockerTitleById = (lockerId) => {
    return lockers.filter((element) => {
      return element.id.toString() === lockerId.toString();
    })[0].title;
  };

  useEffect(() => {
    // console.log();
  }, []);

  return (
    <section
      className={`${item.notice_status === 'RETURNED' || item.notice_status === 'PREPARING_TBILISI' ? 'returned' : packageStatus} flightSection d-flex flex-column`}
      key={keyProp}
    >
      <div className='package-flight-item'>
        {item.trackings ? (
          <div className='flightDetails d-flex flex-row justify-content-between align-items-center'>
            <div className='font-light flightNumberInfo' style={{ display: 'flex', alignItems: 'center' }}>
              {item?.hawb}{' '}
              {item.proxy_country ? (
                item.proxy_country === 'T' ? (
                  <span className='Locker' style={{ paddingLeft: 8, height: '28px' }}>
                    <Truck2Icon width={30} height={30} />
                  </span>
                ) : (
                  <span style={{ paddingLeft: 8, color: 'red' }}>{item.proxy_country}</span>
                )
              ) : (
                <></>
              )}
              <div className='justify-content-center' style={{ display: 'inline' }}>
                {packageStatus !== 'awaiting' && packageStatus !== 'inProgress' && country !== 'UNKNOWN' && country !== null ? (
                  <div className='country-flag' style={{ marginTop: 5 }}>
                    <img height='30px' width={'30px'} src={require('../../assets/images/flags/' + `${country}.svg`)} />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              className={`${eachBranch?.locker > 0 ? 'd-flex flex-column justify-content-between align-items-end' : ''}`}
              onClick={() => eachBranch?.locked === 0 && packageStatus === 'sent' && setOpenHawbBranch(true)}
            >
              {eachBranch?.locker > 0 ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {packageStatus === 'arrived' && item?.locker_sms_code && (
                    <div
                      onClick={() => {
                        setLockerBarcode(item?.locker_sms_code);
                        setShowLockerBarcode(true);
                      }}
                      style={{
                        backgroundColor: '#f68424',
                        cursor: 'pointer',
                        borderRadius: '50%',
                        paddingLeft: 6,
                        paddingTop: 3,
                        paddingBottom: 6,
                        paddingRight: 6,
                        marginRight: 10
                      }}
                    >
                      <BarcodeIcon color='red' />
                    </div>
                  )}
                  <LockerDarkIcon className='Locker' />
                </div>
              ) : null}
              {packageStatus !== 'received' ? (
                lockerData ? (
                  <span className={`font-light addressInfo ${eachBranch?.locked === 0 && packageStatus === 'sent' ? 'changableBranch' : ''}`}>{t(lockerData.title)}</span>
                ) : branchName && lockers ? (
                  branchName.map((singleBranch) => {
                    return (
                      <span key={singleBranch.id} className={`font-light addressInfo ${eachBranch?.locked === 0 && packageStatus === 'sent' ? 'changableBranch' : ''}`}>
                        {t(singleBranch.name)}
                      </span>
                    );
                  })
                ) : null
              ) : item?.locker_id ? (
                <span className={'font-light addressInfo'}>
                  <div style={{ textAlign: 'right' }}>
                    <LockerDarkIcon className='Locker' />
                  </div>
                  {t(getLockerTitleById(item?.locker_id))}
                </span>
              ) : item?.branch ? (
                branchName.map((singleBranch) => {
                  return (
                    <span key={singleBranch.id} className={'font-light addressInfo'}>
                      {t(item?.branch)}
                    </span>
                  );
                })
              ) : null}
            </div>
          </div>
        ) : null}

        {item.hawb_comment_template && Object.prototype.hasOwnProperty.call(item.hawb_comment_template, 'ka') ? (
          <div className='w-full'>
            <div className='w-full status font-light greenBlock'>
              <div className='statusIconDiv d-flex align-items-center'>
                {icon}
                <span dangerouslySetInnerHTML={{ __html: item.hawb_comment_template[currentLang].replace('\n', '<br />') }}></span>
              </div>
            </div>
          </div>
        ) : item.notice_status === 'RETURNED' ? (
          <div className='w-full'>
            <div className='w-full status font-light'>
              <div className='statusIconDiv d-flex align-items-center'>
                <ReturnedIcon />
                <span>{t('_key_package_returned')}</span>
              </div>
            </div>
          </div>
        ) : item.notice_status === 'PREPARING_TBILISI' || item?.notice_status?.startsWith('PREPARING_') ? (
          <div className='w-full'>
            <div className='w-full status font-light' style={{ border: '1px solid #f68424', backgroundColor: '#ffead9' }}>
              <div className='statusIconDiv d-flex align-items-center'>
                <TruckIcon />
                <span>{t('_key_prepare_for_currier')}</span>
              </div>
            </div>
          </div>
        ) : item.notice_status === 'COURIER' ? (
          <div className='w-full'>
            <div className='w-full status font-light' style={{ border: '1px solid #f68424', backgroundColor: '#ffead9' }}>
              <div className='statusIconDiv d-flex align-items-center'>
                <TruckIcon />
                <span>{t('_key_courier_on_way')}</span>
              </div>
            </div>
          </div>
        ) : null}
        {item.tracking ? (
          <HawbTracking icon={icon} tracking={item} packageStatus={packageStatus} item={item} isAwaiting={isAwaiting} />
        ) : (
          item.trackings.map((eachTracking, i) => {
            return (
              <HawbTracking
                key={i}
                icon={icon}
                tracking={eachTracking}
                packageStatus={packageStatus}
                item={item}
                selectedBranch={eachBranch}
                lockerData={lockerData}
                isAwaiting={isAwaiting}
              />
            );
          })
        )}

        {/* {comingTrackingArray &&
          comingTrackingArray?.length > 0 &&
          (documentNeededArray?.length === 0 ? (
            <span className='package_note_danger'>{t('_AT_CUSTOMS_COMMENT')}</span>
          ) : (
            <span className='package_note_danger'>{t('_CUSTOMS_NEEDS_DOCUMENTS')}</span>
          ))} */}
      </div>
      <SideBar openSidebar={openHawbBranch} setOpenSidebar={setOpenHawbBranch} ref={packageRef}>
        {openHawbBranch ? (
          <HawbBranch
            setOpenSidebar={setOpenHawbBranch}
            icon={icon}
            branches={branches}
            lockers={lockers}
            hawb={item.hawb}
            defaultBranch={branchName[0].id}
            defaultLocker={lockerData}
            onClick={refreshPending}
          />
        ) : null}
      </SideBar>
    </section>
  );
}

export function HawbTracking({ item, tracking, icon, packageStatus, selectedBranch, lockerData, isAwaiting }) {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  // console.log(tracking);

  const { token, setAlert, setShowLoader, setHelperTracking, setShowHelperTracking } = useContext(GlobalContext);

  const [openPackageDetails, setOpenPackageDetails] = useState(false);
  const [openDeclaration, setOpenDeclaration] = useState(false);
  const [insurance, setInsurance] = useState(false);
  const [openRejectDeclaration, setOpenRejectDeclaration] = useState(false);
  const [packId, setPackId] = useState();

  const packageRef = useRef([]);
  const declarationRef = useRef([]);

  const changeInsurance = useMutation(
    ({ value, packageId }) => {
      return apiRequest(
        {
          command: 'change_package_setting',
          sessionId: token
        },
        {
          packageId,
          key: 'INSURANCE',
          value
        }
      );
    },
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      },
      onError: (response) => {
        setAlert(['error', t(response.data.message)]);
      }
    }
  );

  useEffect(() => {
    setPackId(tracking.package_id);
  }, []);

  const refreshInsurance = async () => {
    try {
      await changeInsurance.mutateAsync({
        value: insurance,
        packageId: packId
      });
      queryClient.invalidateQueries('getAwaiting');
      dispatch(getAwaitingAsyncThunk());
      queryClient.invalidateQueries('getUsaWarehouse');
    } catch (error) {
      console.log('error', error);
    }
  };

  function openDetailsHawb() {
    packageRef.current.open();
  }

  const resolution = Resolution();

  const { error, loading, image } = useImage(tracking?.shipper || item?.shipper);

  return (
    <>
      <div className='eachPackageWrapper'>
        <div className={`d-flex justify-content-between align-items-center eachPackage ${resolution <= 500 ? 'overflow-hidden' : ''}`} onClick={openDetailsHawb}>
          <div className='d-flex align-items-center'>
            <div className={`d-flex align-items-center justify-content-center rounded-circle shopLogoDiv ${tracking.declared === 0 ? 'declare' : 'declared'}`}>
              {tracking?.declared === 0 ? (
                <ReceivedPackagesIcon className=' shopLogo declareImg' />
              ) : error ? (
                <ReceivedPackagesIcon className=' shopLogo notFoundImage' />
              ) : loading ? null : image ? (
                <img src={image} alt='Shop Logo' className=' shopLogo' />
              ) : (
                <img src={InboxPaperDeclared} alt='Shop Logo' className=' shopLogo notFoundImage' />
              )}
            </div>
            <div className={`packageNumberDiv d-flex flex-column ${resolution <= 500 ? 'overflow-hidden' : ''}`}>
              <div className='packageNumber'>{tracking?.tracking}</div>
              {tracking?.alias_tracking?.length > 0 && <div className='packageNumber aliasTracking'>({tracking?.alias_tracking})</div>}
              {tracking.declared === 0 ? (
                packageStatus !== 'received' &&
                resolution <= 768 && (
                  <Button
                    handleClick={(e) => {
                      tracking.locked === 1 ? setOpenRejectDeclaration(true) : setOpenPackageDetails(true);
                      e.stopPropagation();
                    }}
                    text={t('_key_declaration')}
                    btnStyle='button-danger'
                  />
                )
              ) : (
                <span className='font-light costInfo'>
                  {getAmountWithCurrency(tracking.total_price.toFixed?.(2), JSON?.parse(localStorage.getItem('currencies'))?.[item.country ? item.country : tracking.country])}
                </span>
              )}
            </div>
          </div>
          {packageStatus !== 'received'
            ? resolution > 768 && (
                <div className='optionsIcons d-flex'>
                  {/* <Button
                    handleClick={(e) => {
                      tracking.locked === 1 ? setOpenRejectDeclaration(true) : setOpenDeclaration(true);
                      e.stopPropagation();
                    }}
                    icon={<CutdownGreenIcon />}
                    btnStyle="button-secondary justify-content-center cutdown-button"
                  /> */}
                  {tracking.declared !== undefined &&
                    (tracking?.declared === 1 ? (
                      <Button
                        handleClick={(e) => {
                          tracking.locked === 1 ? setOpenRejectDeclaration(true) : setOpenPackageDetails(true);
                          e.stopPropagation();
                        }}
                        text={t('_RE_DECLARE')}
                        btnStyle='button-secondary justify-content-center'
                      />
                    ) : (
                      <Button
                        handleClick={(e) => {
                          tracking.locked === 1 ? setOpenRejectDeclaration(true) : setOpenPackageDetails(true);
                          e.stopPropagation();
                        }}
                        text={t('_key_declaration')}
                        btnStyle='button-danger justify-content-center '
                      />
                    ))}
                </div>
              )
            : null}
          {resolution <= 768 && (
            // <div className='detailsDots' onClick={() => setOpenPackageDetails(true)}>
            //   <Dots />
            // </div>
            <></>
          )}
        </div>
        <div className='optionsIcons d-flex justify-content-between'>
          {tracking.client_comment ? <div className='clientComment'>{tracking.client_comment}</div> : <div className='clientComment'></div>}
          {(packageStatus === 'awaiting' || packageStatus === 'inProgress') && (
            <div className='justify-content-center align-items-center d-flex'>
              <div className='img-block'>
                {(item?.country ? item.country === 'UNKNOWN' || !item.country : tracking?.country === 'UNKNOWN' || !tracking?.country) ? (
                  ''
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center', gap: 10, alignContent: 'center' }}>
                    {item?.transport_method?.visible && item?.transport_method?.value === 'TRUCK' && (
                      <div>
                        <Truck2Icon width={30} height={30} />
                      </div>
                    )}
                    {item?.transport_method?.visible && item?.transport_method?.value === 'PLANE' && (
                      <div>
                        <Airplane2Icon width={30} height={30} />
                      </div>
                    )}
                    <img
                      height='30px'
                      width={'30px'}
                      style={{ marginRight: '20px' }}
                      src={require('../../assets/images/flags/' + `${item.country ? item.country : tracking.country}.svg`)}
                    />
                  </div>
                )}
                {/* <HelpIcon
                  style={{ width: '30px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    setHelperTracking(tracking?.tracking);
                    setShowHelperTracking(true);
                  }}
                /> */}
              </div>
            </div>
          )}
        </div>
        <div className='package_note_danger'>
          {tracking.notice_status === 'COMING' &&
            (tracking.purchase_order_uploaded === 0
              ? t('_CUSTOMS_NEEDS_DOCUMENTS')
              : tracking.purchase_order_uploaded === 1
              ? t('_AT_CUSTOMS_COMMENT')
              : t('_HAVE_CUSTOMS_REQUEST'))}
        </div>
        <Modal showModal={openRejectDeclaration} setShowModal={setOpenRejectDeclaration}>
          <div className='d-flex flex-column'>
            <p
              className='modalText'
              dangerouslySetInnerHTML={{
                __html: t('_DECLARATION_LOCKED')
              }}
            />
            <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
              <Button
                handleClick={(e) => {
                  e.stopPropagation();
                  setOpenRejectDeclaration(false);
                }}
                type='button'
                btnStyle='bg-orange modal-single-button fill-balance-btn'
                text={t('_CLOSE')}
                color='color-white'
              />
            </div>
          </div>
        </Modal>
      </div>
      <SideBar openSidebar={openPackageDetails} setOpenSidebar={setOpenPackageDetails} ref={packageRef}>
        <PackageDetails
          setOpenSidebar={setOpenPackageDetails}
          item={item}
          eachTracking={tracking}
          status={packageStatus}
          icon={icon}
          selectedBranch={selectedBranch}
          defaultLocker={lockerData}
          packageCountry={item.country ? item.country : tracking.country}
        />
      </SideBar>
      <SideBar openSidebar={openDeclaration} setOpenSidebar={setOpenDeclaration} ref={declarationRef}>
        <Declaration parcel={tracking} handleClose={() => setOpenDeclaration(false)} packageCountry={item.country ? item.country : tracking.country} isAwaiting={isAwaiting} />
      </SideBar>
    </>
  );
}
