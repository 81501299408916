import MicRecorder from 'mic-recorder-to-mp3';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AudioWaveIcon, ChatBotIcon, ChatLoading2Icon, CloseModalWhiteIcon, ImageUploadIcon, MicrophoneIcon, SendRightIcon, StopIcon } from '../../../assets/icons';
import { chatbotSliceActions } from '../../../store/slices/chatbot.slice';
import { sendFileAsyncThunk, sendMessageAsyncThunk } from '../../../store/thunks/chatbot.thunk';
import { getGeorgianDateTime } from '../../../utils/utils';
import GlobalContext from '../../GlobalContext';
import { chatbotContexts } from '../contexts';
import './../Chat.css';
import { ChatMessage } from './ChatMessage';
import { v4 as uuidv4 } from 'uuid';
import { customerSliceActions } from '../../../store/slices/customer.slice';
import { getCustomerInfoAsyncThunk } from '../../../store/thunks/customer.thunk';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export const AIChatWindow = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setToken } = useContext(GlobalContext);
  const messageListRef = useRef(null);
  const uploadFileRef = useRef(null);
  const inputRef = useRef(null);

  const [messageInput, setMessageInput] = useState('');

  const customerId = useSelector((state) => state.customer.customerId);
  const messages = useSelector((state) => state.chatbot.messages);
  const loading = useSelector((state) => state.chatbot.loading);
  const context = useSelector((state) => state.chatbot.context);
  const firstName = useSelector((state) => state.customer.firstName);

  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);
  const [uniqueId, setUniqueId] = useState(null);
  const [fileList, setFileList] = useState([]);

  const defaultWelcomeMessage = {
    // message: 'გთხოვთ აირჩიოთ სასურველი ენა',
    message: 'მოგესალმებით,<br />გთხოვთ მომწეროთ თქვენი შეკითხვა სასურველ ენაზე.<br /><br />Hello,<br />Please write your question in your preferred language.',
    datetime: getGeorgianDateTime()
  };

  useEffect(() => {
    if (messages.length === 0) {
      dispatch(chatbotSliceActions.addMessage(defaultWelcomeMessage));
    }
  }, [customerId, isOpen]);

  useEffect(() => {
    if (!loading) {
      inputRef.current.focus();
    }
  }, [loading]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        console.log('Permission Granted');
        setIsBlocked(false);
      },
      () => {
        console.log('Permission Denied');
        setIsBlocked(true);
      }
    );

    if (isBlocked) {
      console.log('Permission Denied');
    } else {
      scrollDown();
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    console.log('stop');
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);

        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          console.log(fileReader.result);
          dispatch(sendMessageAsyncThunk({ audio: fileReader.result, sessionId: localStorage.getItem('token') }));
          dispatch(
            chatbotSliceActions.addMessage({
              userMessage: true,
              custom: 'audio',
              data: { url: blobURL },
              datetime: getGeorgianDateTime()
            })
          );
        };
        fileReader.readAsDataURL(blob);

        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    setUniqueId(uuidv4());
  }, []);

  const scrollDown = () => {
    messageListRef.current.scrollTo({ top: 100000, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollDown();
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.message === 'Invalid session!') {
      localStorage.removeItem('token');
      setToken(null);
      navigate('/awaiting');
      window.location.reload();
    }
  }, [messages, loading, isRecording]);

  const setCustomer = (sessionId, customer) => {
    if (customer) {
      dispatch(customerSliceActions.setCustomer(customer));
      localStorage.setItem('token', sessionId);
      setToken(sessionId);
      dispatch(getCustomerInfoAsyncThunk());
      navigate('/');
    }
  };

  const sentMessageFromInput = async () => {
    if (messageInput.trim().length > 0 && !loading) {
      const obj = chatbotContexts.filter((element) => {
        return Object.keys(element)[0].toLowerCase() === messageInput.toLocaleLowerCase();
      });

      if (fileList.length > 0) {
        let textData = {};
        if (obj.length > 0) {
          textData = {
            title: messageInput,
            context: Object.values(obj[0])[0],
            message: messageInput,
            sessionId: localStorage.getItem('token'),
            firstName: firstName,
            initiator: 'website'
          };
        } else {
          textData = {
            message: messageInput,
            sessionId: localStorage.getItem('token') ?? uniqueId,
            initiator: 'website'
          };
        }

        dispatch(
          chatbotSliceActions.addMessage({
            userMessage: true,
            message: messageInput,
            custom: 'file_with_message',
            data: fileList,
            datetime: getGeorgianDateTime()
          })
        );
        dispatch(chatbotSliceActions.setLoading(true));
        setMessageInput('');
        setFileList([]);
        try {
          const formData = new FormData();

          fileList.forEach((file, index) => {
            formData.append(`file${index}`, file);
          });

          Object.keys(textData).forEach((key) => {
            formData.append(key, textData[key]);
          });

          const fetchResponse = await fetch('https://usa2georgia.com/private_usa_ws/chatbot-ai.php', {
            method: 'POST',
            body: formData
          });
          const data = await fetchResponse.json();
          const outputMessage = { userMessage: false, message: data.message.message, datetime: getGeorgianDateTime() };
          dispatch(chatbotSliceActions.setLoading(false));
          dispatch(chatbotSliceActions.addMessage(outputMessage));
        } catch (e) {
          return e;
        }
      } else {
        const inputMessageToSend = { userMessage: true, message: messageInput, datetime: getGeorgianDateTime() };
        dispatch(chatbotSliceActions.addMessage(inputMessageToSend));
        if (obj.length > 0) {
          dispatch(
            sendMessageAsyncThunk({
              title: messageInput,
              context: Object.values(obj[0])[0],
              message: messageInput,
              sessionId: localStorage.getItem('token'),
              firstName: firstName
            })
          ).then(() => {
            console.log('asdasdsad');
          });
        } else {
          dispatch(
            sendMessageAsyncThunk({
              message: messageInput,
              sessionId: localStorage.getItem('token') ?? uniqueId,
              initiator: 'website'
            })
          ).then((e) => {
            setCustomer(e.payload.sessionId, e.payload.customer);
          });
        }
      }
    }

    setMessageInput('');
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      sentMessageFromInput();
    }
  };

  const [inputKey, setInputKey] = useState(0);

  const handleFileSelected = (e) => {
    setFileList((prevFiles) => {
      return [...prevFiles, ...Array.from(e.target.files)];
    });

    setInputKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    console.log(fileList);
  }, [fileList]);

  const handleUploadFile = () => {
    if (!loading) {
      uploadFileRef.current.click();
    }
  };

  const handleVoiceRecording = async () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  function getFileTypeImageURL(type) {
    let filename = '';
    if (type === 'image/png') {
      filename = 'png';
    } else if (type === 'image/gif') {
      filename = 'gif';
    } else if (type === 'image/jpeg') {
      filename = 'jpeg';
    } else if (type === 'image/webp') {
      filename = 'jpeg';
    } else if (type === 'application/pdf') {
      filename = 'pdf';
    }

    return `https://usa2georgia.com/static/images/file_types/${filename}.svg`;
  }

  return (
    <div style={{ display: isOpen ? 'block' : 'none' }}>
      <div className='u2g-chat-window-header'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 10 }}>
            <ChatBotIcon fill='#fff' width={28} />
          </div>
          <div>{t('_CHATBOT_HEADER')}</div>
        </div>
        <div
          className='u2g-chat-window-header-close'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <CloseModalWhiteIcon width={20} height={20} />
        </div>
      </div>
      <div className='u2g-chat-window-body'>
        <div className='u2g-chat-messages' ref={messageListRef}>
          {messages.map((element, index) => {
            return (
              <ChatMessage
                custom={element.custom}
                data={element.data}
                key={index}
                sender={element.sender}
                message={element.message}
                datetime={element.datetime}
                userMessage={element.userMessage}
                buttons={element.buttons}
                scrollDown={scrollDown}
              />
            );
          })}
          {loading && (
            <div style={{ width: 40, height: 10, marginLeft: 50 }}>
              <ChatLoading2Icon fill='#f68220' />
            </div>
          )}
          {/* <audio src={blobURL} controls='controls' /> */}
          {isRecording ? (
            <AudioWaveIcon
              fill={loading ? '#ccc' : '#f68220'}
              width={30}
              cursor={loading ? 'normal' : 'pointer'}
              display={context === 'package-info' || context === 'upload-purchase-order' ? 'none' : ''}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={`u2g-chat-input ${loading && 'u2g-chat-input-disabled'}`}>
        <input
          key={inputKey}
          type={'file'}
          style={{ display: 'none' }}
          multiple
          accept='image/png, image/gif, image/jpeg, image/webp, application/pdf'
          ref={uploadFileRef}
          onChange={handleFileSelected}
        />
        <input disabled={loading} value={messageInput} onChange={(e) => setMessageInput(e.currentTarget.value)} onKeyUp={handleKeyUp} ref={inputRef} />
        {messageInput.length > 0 ? (
          <>
            <SendRightIcon fill={loading ? '#ccc' : '#f68220'} width={23} cursor={loading ? 'normal' : 'pointer'} onClick={sentMessageFromInput} />
            <ImageUploadIcon fill={loading ? '#ccc' : '#f68220'} width={30} cursor={loading ? 'normal' : 'pointer'} onClick={handleUploadFile} display={''} />
          </>
        ) : (
          <>
            <SendRightIcon
              fill={'#ccc'}
              width={23}
              cursor={'normal'}
              onClick={sentMessageFromInput}
              display={context === 'package-info' || context === 'upload-purchase-order' ? 'none' : ''}
            />
            <ImageUploadIcon fill={loading ? '#ccc' : '#f68220'} width={30} cursor={loading ? 'normal' : 'pointer'} onClick={handleUploadFile} display={''} />
          </>
        )}
        {false && (
          <div style={{ marginLeft: 5, width: 30 }}>
            {isRecording ? (
              <StopIcon
                fill={loading ? '#ccc' : '#f68220'}
                width={23}
                cursor={loading ? 'normal' : 'pointer'}
                onClick={handleVoiceRecording}
                display={context === 'package-info' || context === 'upload-purchase-order' ? 'none' : ''}
              />
            ) : (
              <MicrophoneIcon
                fill={loading ? '#ccc' : '#f68220'}
                width={18}
                cursor={loading ? 'normal' : 'pointer'}
                onClick={handleVoiceRecording}
                display={context === 'package-info' || context === 'upload-purchase-order' ? 'none' : ''}
              />
            )}
          </div>
        )}
        {fileList.length > 0 && (
          <div className='ai-chat-upload-panel'>
            {fileList.map((element, index) => {
              return (
                <div className='ai-chat-upload-file-item' key={index}>
                  <img style={{ width: 30, userSelect: 'none' }} src={getFileTypeImageURL(element.type)} alt='' title={element.name} />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
