// hooks
import React, { useContext, useState, useRef, useEffect } from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';

// assets
import { Eye, EyeHidden, PasswordIcon, RegistrationIcon, UsernameIcon } from '../../assets/icons';

// components
import Button from '../../components/Button';
import SideBar from '../../components/sidebar/SideBar';
import ForgotPassword from '../sidebar-pages/forgot-password/ForgotPassword';
import Registration from '../sidebar-pages/registration/Registration';
import Input from '../../components/input/Input';
import apiRequest from '../../components/ApiRequest';
import GlobalContext from '../../components/GlobalContext';
import i18n from 'i18next';
import classNames from 'classnames';
import UserIcon from '../../assets/images/user-icon-white.svg';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { getCustomerInfoAsyncThunk } from '../../store/thunks/customer.thunk';
import { chatbotSliceActions } from '../../store/slices/chatbot.slice';

export default function Login() {
  const [openForgot, setOpenForgot] = useState(false);
  const [openReg, setOpenReg] = useState(false);
  const { setToken, setAlert } = useContext(GlobalContext);
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [rememberUser, setRememberUser] = useState(false);
  const [isUserSaved, setIsUserSaved] = useState(false);
  const regSidebarRef = useRef();
  const [activeForm, setActiveForm] = useState('registration');
  const [activeStep, setActiveStep] = useState(0);
  const forgotSidebarRef = useRef();
  const { t } = useTranslation();
  const currentLang = i18n.language;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const login = useMutation(
    () =>
      apiRequest(
        {
          command: 'customer_login'
        },
        {
          userName: localStorage.getItem('savedEmail') || userName,
          password,
          remember: rememberUser
        }
      ),
    {
      onSuccess: (response) => {
        const token = response.data.result;
        localStorage.setItem('agreementToken', response.data.result);
        if (token && response.data.status === 'OK') {
          localStorage.setItem('token', token);
          setToken(token);
          dispatch(getCustomerInfoAsyncThunk());
          // dispatch(chatbotSliceActions.clearChatbot());
          navigate('/');
          if (rememberUser) {
            localStorage.setItem('savedEmail', userName);
          }
        } else if (response.data.message === '_INVALID_USER_OR_PASSWORD') {
          setAlert(['error', t('_INVALID_USER_OR_PASSWORD')]);
        }
      },
      onError: (response) => {
        console.log(response);
      }
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    login.mutate();
  };

  function remember(e) {
    setRememberUser(!rememberUser);
  }

  const removeSavedUser = () => {
    setIsUserSaved(false);
    localStorage.removeItem('savedEmail');
    localStorage.removeItem('userKa');
    localStorage.removeItem('userEn');
    localStorage.removeItem('avatar');
    localStorage.removeItem('extention');
  };

  useEffect(() => {
    if (localStorage.getItem('savedEmail')) {
      setIsUserSaved(true);
    } else {
      setIsUserSaved(false);
    }
  }, [localStorage.getItem('savedEmail')]);

  return (
    <div className='login-container'>
      <div className='d-flex flex-column'>
        <form onSubmit={handleSubmit}>
          {isUserSaved && (
            <div className='saved-username-container'>
              {localStorage.getItem('avatar') && localStorage.getItem('extention') ? (
                <div className='avatar'>
                  <img src={`data:image/${localStorage.getItem('extention')};base64, ${localStorage.getItem('avatar')}`} alt='profile-image' />
                </div>
              ) : (
                <div className='avatar empty'>
                  <img src={UserIcon} alt='user-icon' />
                </div>
              )}
              <div className='ms-4'>
                <p className='text-white mb-2 font-size-16'>{localStorage.getItem(currentLang === 'ka' ? 'userKa' : 'userEn')}</p>
                <p onClick={removeSavedUser} className='text-decoration-underline cursor-pointer color-third-grey font-size-14'>
                  {t('_CHANGE_USER')}
                </p>
              </div>
            </div>
          )}
          <div className='login-inputs'>
            <Input
              name='email'
              type='text'
              customClass='loginInputDiv'
              className={isUserSaved ? 'hidden' : 'login-input primary-btn-w-h bg-transparent'}
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              placeholder={t('_EMAIL')}
              icon={!isUserSaved && <UsernameIcon />}
            />
            <div className='loginInputDiv d-flex justify-content-center align-items-center position-relative'>
              <span className='input-icon'>
                <PasswordIcon />
              </span>
              <span
                className='input-icon-right'
                onClick={() => {
                  setShowPassword(!showPassword);
                }}
              >
                {showPassword ? <Eye style={{ cursor: 'pointer' }} /> : <EyeHidden style={{ cursor: 'pointer' }} />}
              </span>

              <SideBar openSidebar={openForgot} setOpenSidebar={setOpenForgot} ref={forgotSidebarRef}>
                <ForgotPassword openSidebar={openForgot} setOpenSidebar={setOpenForgot} />
              </SideBar>
              <input
                name='password'
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder={t('_PASSWORD')}
                className={'login-input primary-btn-w-h bg-transparent'}
                type={showPassword ? 'text' : 'password'}
              />
            </div>
          </div>
          {!isUserSaved && (
            <div className='checkbox-save-div d-flex' style={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  className={classNames('loginCheckboxDiv', {
                    rememberActive: rememberUser
                  })}
                >
                  <input id='save-user' type='checkbox' className='loginCheckbox' onChange={remember} />
                  <div className='loginCheckboxColor' />
                </div>

                <label className='checkbox-save-span color-white' htmlFor='save-user'>
                  {t('_key_save_me')}
                </label>
              </div>
              <div onClick={() => forgotSidebarRef.current.open()}>
                <span className='password-forgot-link cursor-pointer font-size-14'>{t('_FORGOT_PASSWORD_APP')}</span>
              </div>
            </div>
          )}
          <div className={`mb-3 ${isUserSaved ? 'mt-4' : ''}`}>
            <Button isLoading={login.isLoading} type='submit' color='color-white' btnStyle='primary-btn-w-h bg-orange' text={t('_SIGN_IN')} />
          </div>
          <div>
            <Button handleClick={() => regSidebarRef.current.open()} btnStyle='primary-btn-w-h bg-dark' color='color-white' text={t('_REGISTER_NOW')} icon={<RegistrationIcon />} />
          </div>
        </form>
        <SideBar
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          openSidebar={openReg}
          setOpenSidebar={setOpenReg}
          activeForm={activeForm}
          setActiveForm={setActiveForm}
          ref={regSidebarRef}
        >
          <Registration
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            activeForm={activeForm}
            setActiveForm={setActiveForm}
            openSidebar={openReg}
            setOpenSidebar={setOpenReg}
          />
        </SideBar>
      </div>
    </div>
  );
}
