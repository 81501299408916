import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './SMSCodes.css';
import apiRequest from '../ApiRequest';
import GlobalContext from '../GlobalContext';
import { useNavigate } from 'react-router';

function SMSCodesPopup() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [country, setCountry] = React.useState(null);
  const [store, setStore] = React.useState(null);
  const [storeList, setStoreList] = React.useState([]);
  const [smsMesage, setSmsMesage] = React.useState(null);
  const { token, setAlert, setToken, showLoader, setShowLoader } = useContext(GlobalContext);

  const stores = {
    TURKEY: ['Trendyol', 'OTHER'],
    CHINA: ['Taobao', 'Pinduoduo', 'JD.com', 'Alibaba', 'OTHER']
  };

  useEffect(() => {
    setShowLoader(true);
    setStore(null);

    apiRequest(
      {
        command: 'chatbot_online_store_list',
        sessionId: token,
        method: 'POST'
      },
      {
        country: country
      }
    ).then((res) => {
      setShowLoader(false);
      if (res?.data?.result?.code === 102) {
        navigate('/');
        setToken(null);
      } else {
        console.log(res);
        console.log();
        setStoreList(res?.data?.result);
      }
    });
  }, [country]);

  useEffect(() => {
    if (store) {
      setShowLoader(true);
      apiRequest(
        {
          command: 'get_chatbot_sms_codes',
          sessionId: token,
          method: 'POST'
        },
        {
          country: country,
          onlineStore: store
        }
      ).then((res) => {
        setShowLoader(false);
        if (res?.data?.result?.code === 102) {
          navigate('/');
          setToken(null);
        } else {
          console.log(res);
          console.log();
          setSmsMesage(
            res?.data?.result
              .replaceAll('<strong>', '<span style="font-family: \'HelveticaBold\';">')
              .replaceAll('</strong>', '</span>')
              .replaceAll('<b>', '<span style="font-family: \'HelveticaBold\';">')
              .replaceAll('</b>', '</span>')
          );
        }
      });
    } else {
      setSmsMesage(null);
    }
  }, [store]);

  return (
    <div className='ticket-container d-flex flex-column sideBarChildComponent'>
      <div className='d-flex flex-column height-calculated'>
        <h1 className='font-size-16 mb-3 bold'>{t('_SMS_CODES')}</h1>
        <div style={{}} className='d-flex flex-column justify-content-between font-size-14'>
          <div className=''>
            <div className='pr-60' style={{ maxHeight: '80vh', overflow: 'auto' }}>
              <div className='u2g-sms-block'>
                <div>{t('_CHOOSE_COUNTRY')}</div>
                <div className='u2g-sms-country-buttons-container'>
                  <div className={`u2g-sms-action-button ${country === 'TURKEY' ? 'u2g-sms-action-button-selected' : ''}`} onClick={() => setCountry('TURKEY')}>
                    {t('_TURKEY')}
                  </div>
                  <div className={`u2g-sms-action-button ${country === 'CHINA' ? 'u2g-sms-action-button-selected' : ''}`} onClick={() => setCountry('CHINA')}>
                    {t('_CHINA')}
                  </div>
                </div>
              </div>
              {country && (
                <div className='u2g-sms-block'>
                  <div>{t('_TURKEY_CHOOSE_ONLINE_STORE')}</div>
                  <div className='u2g-sms-country-buttons-container'>
                    {storeList?.map((element) => {
                      return (
                        <div key={element} className={`u2g-sms-action-button ${store === element ? 'u2g-sms-action-button-selected' : ''}`} onClick={() => setStore(element)}>
                          {element === 'OTHER' ? t('_OTHER_TURKEY_ONLINE_STORE') : element}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {smsMesage && (
                <div className='u2g-sms-block'>
                  <div className='u2g-sms-country-buttons-container'>
                    <div dangerouslySetInnerHTML={{ __html: smsMesage }}></div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SMSCodesPopup;
