// hooks
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

// assets
import { BranchesLocationIcon, SettingsIcon, Sms2Icon, Sms3Icon, SmsIcon, TicketIcon, TransactionsIcon } from '../../assets/icons';
import GlobalContext from '../GlobalContext';
import SideBar from '../sidebar/SideBar';
import Tickets from '../../pages/settings/components/tickets/Tickets';
import SMSCodesPopup from '../sms-codes/SMSCodesPopup';

const SettingsNav = () => {
  const { t } = useTranslation();
  const [openSettings, setOpenSettings] = useState(false);
  const [openSMSCodes, setOpenSMSCodes] = useState(false);

  const footerNav = [
    {
      name: t('_TRANSACTIONS'),
      path: 'transactions',
      icon: <TransactionsIcon />
    },
    {
      name: t('_ADDRESSES'),
      path: 'address',
      icon: <BranchesLocationIcon />
    },
    {
      name: t('_SETTINGS'),
      path: 'settings',
      icon: <SettingsIcon />
    }
    // {
    //   name: t('_title_tickets'),
    //   path: 'mails',
    //   icon: <TicketIcon />
    // }
  ];

  const location = useLocation();
  const path = location.pathname.substring(1);

  return (
    <div>
      {footerNav.map((item, index) => (
        <div key={index} className={`d-flex align-items-center mb-4 cursor-pointer ${path === item.path ? 'multiple-colors-active' : ' hover-multiple-colors'}`}>
          <div className='settings-nav-icon-container'>{item.icon}</div>
          <Link to={`/${item.path}`}>
            <span className={` font-roman ${path === item.path ? 'color-white' : 'color-gray hover-white'} ms-3 font-size-14`}>{item.name}</span>
          </Link>
        </div>
      ))}
      <div
        className={'d-flex align-items-center mb-4 cursor-pointer hover-multiple-colors'}
        onClick={() => {
          setOpenSettings(true);
        }}
      >
        <div className='settings-nav-icon-container'>
          <TicketIcon />
        </div>
        <span className={'font-roman ms-3 font-size-14 color-gray hover-white'}>{t('_title_tickets')}</span>
      </div>

      <div
        className={'d-flex align-items-center mb-4 cursor-pointer hover-multiple-colors'}
        onClick={() => {
          setOpenSMSCodes(true);
        }}
      >
        <div className='settings-nav-icon-container'>
          <Sms3Icon style={{ width: 18, height: 18 }} />
        </div>
        <span className={'font-roman ms-3 font-size-14 color-gray hover-white'}>{t('_SMS_CODES')}</span>
      </div>

      <SideBar openSidebar={openSettings} setOpenSidebar={setOpenSettings}>
        <Tickets setOpenSettings={setOpenSettings} />
      </SideBar>

      <SideBar openSidebar={openSMSCodes} setOpenSidebar={setOpenSMSCodes}>
        <SMSCodesPopup />
      </SideBar>
    </div>
  );
};

export default SettingsNav;
