import React from 'react';
import './Radio.scss';
import { TickIcon } from '../../assets/icons';
import { v4 as uuidv4 } from 'uuid';

const Radio = ({ selected, disabled, text, icon, value, name, ...props }) => {
  const id = uuidv4();

  return (
    <div className='d-flex align-items-center' style={{ flexDirection: icon ? 'column-reverse' : 'initial', cursor: disabled ? 'not-allowed' : 'pointer' }}>
      <div>
        <label className='custom-radio-btn'>
          <input value={value} checked={selected} disabled={disabled === true} type='radio' name={name} {...props} id={id} />
          <div className={`checkmark checkmark-checked-${selected}`}>
            <div className='d-flex justify-content-center checkbox-div align-items-center'>{selected && <TickIcon />}</div>
          </div>
        </label>
      </div>
      {icon ? (
        <label htmlFor={id} style={{ width: 32, cursor: disabled ? 'not-allowed' : 'pointer', opacity: disabled ? 0.5 : 1 }}>
          {icon}
        </label>
      ) : (
        <span className='font-size-14 color-third-grey bold'>{text}</span>
      )}
    </div>
  );
};

export default Radio;
