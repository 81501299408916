// hooks
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Airplane2Icon, RailwayIcon, ShipIcon, Truck2Icon } from '../../../../assets/icons';
import apiRequest from '../../../../components/ApiRequest';
import Button from '../../../../components/Button';
import GlobalContext from '../../../../components/GlobalContext';
import Modal from '../../../../components/modal/Modal';
import Radio from '../../../../components/radio/Radio';
import { getCustomerInfoAsyncThunk } from '../../../../store/thunks/customer.thunk';

const TruckSettings = ({ userInfo, setOpenSettings }) => {
  const dispatch = useDispatch();
  const chinaTransportMethods = useSelector((state) => state.customer.chinaTransportMethods);
  const chinaTransport = useSelector((state) => state.customer.settings.chinaTransport);

  const { setAlert, token } = useContext(GlobalContext);
  const { t } = useTranslation();
  const [showSelectTransportationMethodModal, setShowSelectTransportationMethodModal] = useState(false);
  const [showWaitingSwitchModal, setShowWaitingSwitchModal] = useState(false);

  const [hasWaiting, setHasWaiting] = useState(false);

  const [newTransportMethod, setNewTransportMethod] = useState(chinaTransport);
  const [transportMethod, setTransportMethod] = useState(chinaTransport);

  const [switchToModalText, setSwitchToModalText] = useState('');
  const [waitingModalText, setWaitingModalText] = useState('');

  useEffect(() => {}, [transportMethod]);

  const saveSettings = useMutation(
    (waiting) =>
      apiRequest(
        {
          command: 'save_customer_setting_transport_method',
          sessionId: token
        },
        {
          transportMethod: newTransportMethod,
          doAwaiting: 1 // waiting ? 1 : 0
        }
      ),
    {
      onSuccess: (response) => {
        if (response.data.status === 'OK') {
          setAlert(['success', t(response.data.message)]);

          setTransportMethod(newTransportMethod);
          setShowSelectTransportationMethodModal(false);
          setShowWaitingSwitchModal(false);
          dispatch(getCustomerInfoAsyncThunk());
        } else {
          setAlert(['error', t(response.data.message)]);
        }
      }
    }
  );

  const requestSwitchAgree = async () => {
    if (hasWaiting) {
      setShowWaitingSwitchModal(true);
    } else {
      try {
        await saveSettings.mutateAsync(false);
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const requestSwitchCancel = async () => {
    setShowSelectTransportationMethodModal(false);
  };

  const waitingSwitchAgree = async () => {
    try {
      await saveSettings.mutateAsync(true);
    } catch (error) {
      console.log('error', error);
    }
  };

  const waitingSwitchCancel = async () => {
    try {
      await saveSettings.mutateAsync(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const switchTransportMethod = async (method) => {
    setNewTransportMethod(method);
    apiRequest(
      {
        command: 'check_awaiting_for_transport_method',
        sessionId: localStorage.getItem('token')
      },
      {
        transportMethod: method
      }
    ).then((response) => {
      // setHasWaiting(parseInt(response.data?.result) > 0);
      setHasWaiting(false);

      // captions
      if (method === 'PLANE') {
        setSwitchToModalText('_SWITCH_SETTING_TO_PLANE_PER_PACKAGE_CONFIRMATION_WEB');
        setWaitingModalText('_WAITING_TO_PLANE_CONFIRMATION');
      } else if (method === 'TRUCK') {
        setSwitchToModalText('_SWITCH_SETTING_TO_TRUCK_PER_PACKAGE_CONFIRMATION_WEB');
        setWaitingModalText('_WAITING_TO_TRUCK_CONFIRMATION');
      }

      setShowSelectTransportationMethodModal(true);
    });
  };

  return (
    <div className='d-flex flex-column height-100 justify-content-between sideBarChildComponent'>
      <h1 className='font-size-16 bold'>{t('_CHINA_TRUCK_TRANSPORTATION_GLOBAL_CAPTION')}</h1>
      <form className='d-flex flex-column height-100 justify-content-between form'>
        <div className='sidebar-child-scroll'>
          <div className='pr-60'>
            <div>{t('_CHINA_TRUCK_TRANSPORTATION_DESCRIPTION_GLOBAL')}</div>
            <div>
              <div className={'d-flex py-3 align-items-center flex-row'} style={{ justifyContent: 'space-around' }}>
                {chinaTransportMethods?.map((element) => {
                  let icon = null;
                  if (element === 'PLANE') {
                    icon = <Airplane2Icon />;
                  } else if (element === 'TRUCK') {
                    icon = <Truck2Icon />;
                  }
                  return (
                    <Radio
                      key={element}
                      icon={icon}
                      value={element}
                      selected={transportMethod === element}
                      onChange={() => {
                        switchTransportMethod(element);
                      }}
                    />
                  );
                })}
              </div>
            </div>

            {/* <div
              className='bold'
              style={{
                border: '1px solid #f68424',
                backgroundColor: 'rgba(246, 132, 36, 0.1)',
                borderRadius: 8,
                padding: 20,
                fontSize: 14,
                color: '#f68424',
                fontWeight: 'bold'
              }}
              dangerouslySetInnerHTML={{ __html: t('_CHINA_TRUCK_TRANSPORTATION_WARNING') }}
            ></div> */}
          </div>
        </div>
      </form>

      <Modal showModal={showSelectTransportationMethodModal} setShowModal={setShowSelectTransportationMethodModal} modal={true}>
        <div className='d-flex flex-column'>
          <p className='modalText' style={{ maxHeight: 500, overflow: 'auto', paddingRight: 15 }} dangerouslySetInnerHTML={{ __html: t(switchToModalText) }}></p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button handleClick={requestSwitchAgree} type='button' btnStyle='bg-orange me-4 fill-balance-btn' text={t('_AGREE')} color='color-white' isLoading={false} />
            <Button handleClick={requestSwitchCancel} type='button' btnStyle='bg-orange fill-balance-btn secondary-btn-bg' text={t('_NOT_AGREE')} color='color-white' />
          </div>
        </div>
      </Modal>

      <Modal showModal={showWaitingSwitchModal} setShowModal={setShowWaitingSwitchModal} modal={true}>
        <div className='d-flex flex-column'>
          <p className='modalText' dangerouslySetInnerHTML={{ __html: t(waitingModalText) }}></p>
          <div className='d-flex align-items-center justify-content-center modal-btn-div mt-4'>
            <Button
              handleClick={() => {
                waitingSwitchAgree();
              }}
              type='button'
              btnStyle='bg-orange me-4 fill-balance-btn'
              text={t('_YES')}
              color='color-white'
              isLoading={false}
            />
            <Button
              handleClick={() => {
                waitingSwitchCancel();
              }}
              type='button'
              btnStyle='bg-orange fill-balance-btn secondary-btn-bg'
              text={t('_NO')}
              color='color-white'
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TruckSettings;
