/* eslint-disable space-before-function-paren */
// hooks
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import React, { useEffect, useRef, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

// scss
import './App.scss';
import './scss/CssReset.css';
import './scss/Forms.scss';

// libraries
import * as dayjs from 'dayjs';
import 'dayjs/locale/ka';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// components
import HttpApi from 'i18next-http-backend';
import apiRequest from './components/ApiRequest';
import GlobalContext from './components/GlobalContext';
import ProtectedRoutes from './components/ProtectedRoutes';
import Alert from './components/alert/Alert';
import CustomPopup from './components/custom-popup/CustomPopup';
import DocumentRequest from './components/document-request/DocumentRequest';
import Loader from './components/loader/Loader';
import Popup from './components/popup/Popup';
import SideBar from './components/sidebar/SideBar';
import { Address, Branches, Faq, FlightsSchedule, Invoice, Notifications, Settings, Transactions } from './pages';
import Agreement from './pages/agreement/Agreement';
import AuthPage from './pages/auth/AuthPage';
import CourierInvoice from './pages/courier-invoice/CourierInvoice';
import Arrived from './pages/dashboard/tabs/Arrived';
import Awaiting from './pages/dashboard/tabs/Awaiting';
import Pending from './pages/dashboard/tabs/Pending';
import Received from './pages/dashboard/tabs/Received';
import WareHouse from './pages/dashboard/tabs/WareHouse';
import IpayStatus from './pages/ipay-status/IpayStatus';
import Mails from './pages/mails/Mails';
import NotificationsPage from './pages/notifications';
import Tickets from './pages/settings/components/tickets/Tickets';
import FindPackage from './pages/track-package/TrackPackage';
import UserPolicy from './pages/user-policy/UserPolicy';
import { getCustomerInfoAsyncThunk } from './store/thunks/customer.thunk';
// import Help from './pages/help/Help';
import Helper from './pages/settings/components/helper/Helper';
// import HelpPage from './pages/help/HelpPage';
import { Chat } from './components/chat/Chat';
import PriceCalculatorNew from './pages/sidebar-pages/PriceCalculatorNew';
import SubmitForm from './pages/submit-form/SubmitForm';
import { UnsubscribePage } from './pages/unsubscribe/UnsubscribePage';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false
      // staleTime: 10000
    }
  }
});

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'ka'],
    backend: {
      loadPath: function (lngs, namespaces) {
        return lngs[0];
      },
      request: function (_, url, __, callback) {
        // localStorage.setItem('selectedLanguage', url === null ? 'ka' : url);
        // const langKey = url === 'en' ? 'english' : 'georgian';

        const translationsCache = null; // localStorage.getItem('translations');
        let translations;
        if (translationsCache) {
          try {
            translations = JSON.parse(translationsCache);
            callback(null, {
              status: 'ok',
              data: translations // [langKey]
            });
          } catch (e) {}
        }

        apiRequest(
          {
            command: 'new_get_language_pack',
            method: 'GET'
          },
          {
            language: url
          }
        ).then((response) => {
          // localStorage.setItem('translations', JSON.stringify(response.data.result));
          if (!translations) {
            callback(null, {
              status: 'ok',
              data: response.data.result // [langKey]
            });
          }
        });
      }
    },
    parseMissingKeyHandler: function (lngs, _, __, ___) {
      const cacheExists = localStorage.getItem('translations');
      return cacheExists ? lngs : '';
    },
    // resources: {
    //   en: {
    //     translation: require('./components/i18-n/en.json')
    //   },
    //   ka: {
    //     translation: require('./components/i18-n/ka.json')
    //   }
    // },
    // lang: localStorage.getItem('selectedLanguage'),
    fallbackLng: localStorage.getItem('i18nextLng') === 'en' ? 'ka' : 'en',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  });

i18n.on('languageChanged', function (loc) {
  dayjs.locale(loc);
});

function App() {
  const [isSidebarOpened, setIsSidebarOpened] = useState(false);
  const [token, setToken] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showTickets, setShowTickets] = useState(false);
  const [alert, setAlert] = useState([]);
  const dispatch = useDispatch();
  const [customerId, setCustomerId] = useState(null);
  const [getCustomerInfo, setGetCustomerInfo] = useState(null);
  // const _customerId = useSelector((state) => state.customer.customerId);
  const needsUploadIdentification = useSelector((state) => state.customer.flags.needsUploadIdentification);
  const [helperTracking, setHelperTracking] = useState(null);
  const [showHelperTracking, setShowHelperTracking] = useState(false);

  const calculatorSidebarRef = useRef();
  const [showCalculator, setShowCalculator] = useState(false);
  const currentLocation = window.location.pathname;

  useEffect(() => {
    // console.log(customerId);
  }, []);

  useEffect(() => {
    const localStorageToken = localStorage.getItem('token') || null;
    // if (localStorage.getItem('selectedLanguage') === undefined) {
    //   localStorage.setItem('selectedLanguage', 'ka');
    // }
    setToken(localStorageToken);
    dispatch(getCustomerInfoAsyncThunk());
  }, []);

  const ticketSideBarRef = useRef();
  const trackingHelperSideBarRef = useRef();

  // const [ip, setIP] = useState('');
  // const getData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/');
  //   console.log(res.data);
  //   setIP(res.data.IPv4);
  // };

  // useEffect(() => {
  //   getData();
  // }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <GlobalContext.Provider
          value={{
            token,
            setToken,
            customerId,
            setCustomerId,
            getCustomerInfo,
            setGetCustomerInfo,
            showLoader,
            setShowLoader,
            alert,
            setAlert,
            isSidebarOpened,
            setIsSidebarOpened,
            helperTracking,
            setShowTickets,
            setHelperTracking,
            setShowHelperTracking,
            showCalculator,
            setShowCalculator
          }}
        >
          {/* {(customerId === '2228' || customerId === '54' || customerId === '427191' || customerId === '15' || customerId === '4143') && token !== null ? <></> : <Kommunicate />} */}
          {/* token !== null && */}
          {!currentLocation.includes('pop_notifications') && <Chat />}
          <Loader />
          <Alert />
          <Popup />
          <CustomPopup />

          <SideBar openSidebar={showHelperTracking} setOpenSidebar={setShowHelperTracking} ref={trackingHelperSideBarRef}>
            <Helper helperTracking={helperTracking} />
          </SideBar>

          <SideBar openSidebar={showCalculator} setOpenSidebar={setShowCalculator} ref={calculatorSidebarRef}>
            <PriceCalculatorNew />
          </SideBar>

          {/* <SideBar openSidebar={true}>
            <TestComponent />
          </SideBar> */}

          <SideBar openSidebar={showTickets} setOpenSidebar={setShowTickets} ref={ticketSideBarRef}>
            <Tickets setOpenSettings={setShowTickets} />
          </SideBar>

          {needsUploadIdentification && <DocumentRequest />}
          <Routes>
            <Route path='/' element={!localStorage.getItem('token') ? <AuthPage /> : <Awaiting />}>
              <Route path=':lang' element={!localStorage.getItem('token') ? <AuthPage /> : <Awaiting />} />
            </Route>
            <Route path='/agreement' element={<Agreement />}>
              <Route path=':lang' element={<Agreement />} />
            </Route>
            <Route path='/pop_notifications' element={<NotificationsPage />} />
            <Route path='/user-policy' element={<UserPolicy />}>
              <Route path=':lang' element={<UserPolicy />} />
            </Route>
            <Route path='/branches' element={<Branches />}>
              <Route path=':lang' element={<Branches />} />
            </Route>
            <Route path='/flights-schedule' element={<FlightsSchedule />}>
              <Route path=':lang' element={<FlightsSchedule />} />
            </Route>
            {/* <Route path='/help' element={<HelpPage />} /> */}
            <Route path='/faq' element={<Faq />} />
            <Route path='/track-package' element={<FindPackage />}>
              <Route path=':lang' element={<FindPackage />}>
                <Route path=':term' element={<FindPackage />} />
              </Route>
            </Route>
            <Route path='/submit' element={<SubmitForm />} />
            <Route path='/unsubcribe/:hash' element={<UnsubscribePage />} />

            <Route element={<ProtectedRoutes localStorageToken={token} />}>
              <Route path='/' element={<Awaiting />} />
              <Route path='/warehouse' element={<WareHouse />} />
              <Route path='/pending' element={<Pending />} />
              <Route path='/arrived' element={<Arrived />} />
              <Route path='/received' element={<Received />} />
              <Route path='/address' element={<Address />} />
              <Route path='/notifications' element={<Notifications />} />
              <Route path='/settings' element={<Settings />} />
              <Route path='/mails' element={<Mails />} />
              <Route path='/transactions' element={<Transactions />} />
              <Route path='/ipay' element={<IpayStatus />} />
            </Route>
            <Route path='/invoice' element={<Invoice />} />
            <Route path='/courier_invoice' element={<CourierInvoice />} />

            <Route path='*' element={!localStorage.getItem('token') ? <AuthPage /> : <Awaiting />} />
          </Routes>
        </GlobalContext.Provider>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
