import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

import { addTrackingAsyncThunk, getAwaitingAsyncThunk } from '../../store/thunks/packages.thunk';

import Input from '../input/Input';
import { PackagesMainIcon } from '../../assets/icons';
import Button from '../Button';
import GlobalContext from '../GlobalContext';
import Resolution from '../hooks/resolution';
import CountryChooser from './CountryChooser';
import { useQueryClient } from 'react-query';

const AddTrackingNew = () => {
  // Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setToken, setAlert } = useContext(GlobalContext);
  const navigate = useNavigate();

  // Local State
  const [trackingCode, setTrackingCode] = useState('');
  const [message, setMessage] = useState('');

  const [country, setCountry] = useState(null);
  const [showAddTracking, setShowAddTracking] = useState(false);

  // Redux State
  const addTrackingLoading = useSelector((state) => state.packages.customLoadings.addTracking);

  // Variables
  const resolution = Resolution();
  const queryClient = useQueryClient();
  
  // Handlers
  const addNewTracking = async (e) => {
    e.preventDefault();
    if (trackingCode.trim().length === 0) {
      setMessage(t('_INVALID_TRACKING'));
    } else if (country === null) {
      setMessage(t('_INVALID_ADD_TRACKING_COUNTRY'));
    } else {
      dispatch(addTrackingAsyncThunk({ tracking: trackingCode, country: country })).then(({ payload }) => {
        if (payload?.code === 102) {
          navigate('/');
          setToken(null);
        } else if (payload?.status === 'OK') {
          setAlert(['success', t(payload.message)]);
          setTrackingCode('');
          setMessage('');
          setShowAddTracking(false);
          setCountry(null);
          queryClient.invalidateQueries('userInfo');
          dispatch(getAwaitingAsyncThunk()).then(({ payload }) => {
            if (payload?.code === 102) {
              navigate('/');
              setToken(null);
            }
          });
        } else {
          setMessage(t(payload.message));
        }
      });
    }
  };

  return (
    <div className='bg-white mb-4 p-4 pb-1 border-rad-12'>
      <Row>
        <Col lg={7} md={6} xs={12}>
          <div>
            <h2 className='color-orange font-size-16'>{t('_ADD_PACKAGE')}</h2>
            <div className='h-20px mb-3'>
              <p className='color-red font-size-14'>{message}</p>
            </div>
            <form onSubmit={addNewTracking}>
              <Input
                onChange={(e) => setTrackingCode(e.target.value)}
                value={trackingCode}
                onFocus={() => {
                  setShowAddTracking(true);
                }}
                label={t('_TRACKING_CODE')}
              />
              <div className={`add-tracking-btn-new ${showAddTracking}`}>
                <CountryChooser country={country} setCountry={setCountry} />
                <div className='add-tracking-buttons'>
                  <Button type='submit' text={t('_ADD')} btnStyle='primary-btn-w-h color-white bg-orange' isLoading={addTrackingLoading} />
                  <Button
                    type='button'
                    text={t('_CANCEL')}
                    btnStyle='primary-btn-w-h '
                    handleClick={() => {
                      setShowAddTracking(false);
                      setTrackingCode('');
                      setMessage('');
                      setCountry(null);
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </Col>
        {resolution <= 768 ? null : (
          <Col lg={5} md={6}>
            <div className='add-tracking-icon'>
              <PackagesMainIcon />
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default AddTrackingNew;
