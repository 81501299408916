import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const sendMessageAsyncThunk = createAsyncThunk('sendMessageAsyncThunk', async (messageJSON) => {
  const settings = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(messageJSON)
  };

  try {
    const fetchResponse = await fetch('https://usa2georgia.com/private_usa_ws/chatbot-ai.php', settings);
    const data = await fetchResponse.json();
    return data;
  } catch (e) {
    return e;
  }
});

export const sendFileAsyncThunk = createAsyncThunk('sendFileAsyncThunk', async ({ fileList, textData }) => {
  const formData = new FormData();

  fileList.forEach((file, index) => {
    formData.append(`file${index}`, file);
  });

  Object.keys(textData).forEach((key) => {
    formData.append(key, textData[key]);
  });

  try {
    const response = await axios.post('https://usa2georgia.com/private_usa_ws/chatbot-ai.php', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {}

  // try {
  //   const fetchResponse = await fetch('https://usa2georgia.com/private_usa_ws/chatbot-ai.php', settings);
  //   const data = await fetchResponse.json();
  //   return data;
  // } catch (e) {
  //   return e;
  // }
});
