// hooks
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// components
import { Col, Row } from 'react-bootstrap';
import OrangeLine from '../../components/OrangeLine';
import Button from '../../components/Button';
import GlobalContext from '../../components/GlobalContext';
import { useLocation } from 'react-router';

export default function Prices({ route, navigation }) {
  const { t } = useTranslation();
  const { setShowCalculator } = useContext(GlobalContext);
  const { hash } = useLocation();
  const ref = useRef();

  const showCalculatorHandler = () => {
    setShowCalculator(true);
  };

  useEffect(() => {
    if (hash === '#prices') {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', alignToTop: true });
    }
  }, []);

  return (
    <div>
      <Row className='prices-row' id='prices'>
        <Col lg={12} md={12} xs={12} style={{ padding: '0 25px' }}>
          <div className='prices-right-div'>
            <OrangeLine />
            <h2 className='about-us-title mt-2'>{t('_PRICING')}</h2>
            <div
              className='color-secondary-grey how-usa2-right-p font-size-14'
              dangerouslySetInnerHTML={{
                __html: t('_SHIPPING_FEE_DESCRIPTION')
              }}
            />
            <hr />
            <div style={{ paddingBottom: 10 }}>
              <div className='font-size-14' style={{ fontFamily: 'HelveticaBoldCaps', color: '#8a9099', marginBottom: 20 }}>
                <b>{t('_INSURENCE_TABLE_HEADER')}</b>
              </div>
              <table className='font-size-14 insurance-table' style={{ borderBottom: '1px solid #ccc' }}>
                <tbody>
                  <tr>
                    <td className='font-size-14' style={{ fontFamily: 'HelveticaBoldCaps', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                      {t('_INSURENCE_TABLE_COL1_HEADER')}
                    </td>
                    <td className='font-size-14' style={{ fontFamily: 'HelveticaBoldCaps', backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                      {t('_INSURENCE_TABLE_COL2_HEADER')}
                    </td>
                  </tr>
                  <tr>
                    <td> {t('_INSURENCE_TABLE_ROW1_1_VALUE')}</td>
                    <td> {t('_INSURENCE_TABLE_COL1_2_VALUE')}</td>
                  </tr>
                  <tr>
                    <td> {t('_INSURENCE_TABLE_ROW2_1_VALUE')}</td>
                    <td> {t('_INSURENCE_TABLE_COL2_2_VALUE')}</td>
                  </tr>
                  <tr>
                    <td> {t('_INSURENCE_TABLE_ROW3_1_VALUE')}</td>
                    <td> {t('_INSURENCE_TABLE_COL3_2_VALUE')}</td>
                  </tr>
                  <tr>
                    <td> {t('_INSURENCE_TABLE_ROW4_1_VALUE')}</td>
                    <td> {t('_INSURENCE_TABLE_COL4_2_VALUE')}</td>
                  </tr>
                  <tr>
                    <td> {t('_INSURENCE_TABLE_ROW5_1_VALUE')}</td>
                    <td> {t('_INSURENCE_TABLE_COL5_2_VALUE')}</td>
                  </tr>
                  <tr>
                    <td> {t('_INSURENCE_TABLE_ROW6_1_VALUE')}</td>
                    <td> {t('_INSURENCE_TABLE_COL6_2_VALUE')}</td>
                  </tr>
                  <tr>
                    <td> {t('_INSURENCE_TABLE_ROW7_1_VALUE')}</td>
                    <td> {t('_INSURENCE_TABLE_COL7_2_VALUE')}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className='color-secondary-grey how-usa2-right-p font-size-14'
              dangerouslySetInnerHTML={{
                __html: t('_SHIPPING_FEE_DESCRIPTION_BOTTOM')
              }}
            />

            <div style={{ display: 'flex', justifyContent: 'center' }} ref={ref}>
              <div style={{ width: 300, marginTop: 30 }}>
                <Button handleClick={showCalculatorHandler} type='submit' color='color-white' btnStyle='primary-btn-w-h bg-orange' text={t('_PRICE_CALCULATOR')} />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}
