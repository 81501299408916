import MicRecorder from 'mic-recorder-to-mp3';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AudioWaveIcon, ChatBotIcon, ChatLoading2Icon, CloseModalWhiteIcon, ImageUploadIcon, MicrophoneIcon, SendRightIcon, StopIcon } from '../../../assets/icons';
import { chatbotSliceActions } from '../../../store/slices/chatbot.slice';
import { sendFileAsyncThunk, sendMessageAsyncThunk } from '../../../store/thunks/chatbot.thunk';
import { getGeorgianDateTime } from '../../../utils/utils';
import GlobalContext from '../../GlobalContext';
import { chatbotContexts } from '../contexts';
import './../Chat.css';
import { ChatMessage } from './ChatMessage';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

export const ChatWindow = ({ isOpen, setIsOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setToken } = useContext(GlobalContext);
  const messageListRef = useRef(null);
  const uploadFileRef = useRef(null);

  const [messageInput, setMessageInput] = useState('');

  const customerId = useSelector((state) => state.customer.customerId);
  const messages = useSelector((state) => state.chatbot.messages);
  const loading = useSelector((state) => state.chatbot.loading);
  const context = useSelector((state) => state.chatbot.context);
  const firstName = useSelector((state) => state.customer.firstName);

  const [isRecording, setIsRecording] = useState(false);
  const [blobURL, setBlobURL] = useState('');
  const [isBlocked, setIsBlocked] = useState(false);

  const testUsers = [427191, 15, 4143];

  const defaultWelcomeMessage = {
    message: 'გთხოვთ აირჩიოთ სასურველი ენა',
    // message: 'მოგესალმებით, რით შემიძლია დაგეხმაროთ?',
    buttons: [
      {
        title: 'English',
        clickArgs: {
          context: 'main'
        }
      },
      {
        title: 'ქართული',
        clickArgs: {
          context: 'main'
        }
      }
    ],
    datetime: getGeorgianDateTime()
  };

  useEffect(() => {
    if (customerId !== null && messages.length === 0 && !testUsers.includes(customerId)) {
      dispatch(chatbotSliceActions.addMessage(defaultWelcomeMessage));
    }

    if (testUsers.includes(customerId)) {
      dispatch(chatbotSliceActions.clearChatbot());
      dispatch(
        sendMessageAsyncThunk({
          title: '---HELLO---',
          context: 'main',
          message: '---HELLO---',
          sessionId: localStorage.getItem('token'),
          firstName: firstName
        })
      );
    }
  }, [customerId]);

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia(
      { audio: true },
      () => {
        console.log('Permission Granted');
        setIsBlocked(false);
      },
      () => {
        console.log('Permission Denied');
        setIsBlocked(true);
      }
    );

    if (isBlocked) {
      console.log('Permission Denied');
    } else {
      scrollDown();
      Mp3Recorder.start()
        .then(() => {
          setIsRecording(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stopRecording = () => {
    console.log('stop');
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        setBlobURL(blobURL);

        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          console.log(fileReader.result);
          dispatch(sendMessageAsyncThunk({ audio: fileReader.result, sessionId: localStorage.getItem('token') }));
          dispatch(
            chatbotSliceActions.addMessage({
              userMessage: true,
              custom: 'audio',
              data: { url: blobURL },
              datetime: getGeorgianDateTime()
            })
          );
        };
        fileReader.readAsDataURL(blob);

        setIsRecording(false);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    // console.log(blobURL);
  }, [blobURL]);

  const scrollDown = () => {
    messageListRef.current.scrollTo({ top: 100000, behavior: 'smooth' });
  };

  useEffect(() => {
    scrollDown();
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.message === 'Invalid session!') {
      localStorage.removeItem('token');
      setToken(null);
      navigate('/awaiting');
      window.location.reload();
    }
  }, [messages, loading, isRecording]);

  const sentMessageFromInput = () => {
    if (messageInput.trim().length > 0 && !loading) {
      const obj = chatbotContexts.filter((element) => {
        return Object.keys(element)[0].toLowerCase() === messageInput.toLocaleLowerCase();
      });

      if (obj.length > 0) {
        dispatch(
          sendMessageAsyncThunk({
            title: messageInput,
            context: Object.values(obj[0])[0],
            message: messageInput,
            sessionId: localStorage.getItem('token'),
            firstName: firstName
          })
        );
      } else {
        dispatch(
          sendMessageAsyncThunk({
            message: messageInput,
            sessionId: localStorage.getItem('token'),
            firstName: firstName
          })
        );
      }

      const inputMessageToSend = { userMessage: true, message: messageInput, datetime: getGeorgianDateTime() };
      dispatch(chatbotSliceActions.addMessage(inputMessageToSend));
      setMessageInput('');
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      sentMessageFromInput();
    }
  };

  const handleFileSelected = (e) => {
    const file = e.target.files[0];
    e.target.value = null;
    const formData = new FormData();
    formData.append('sessionId', localStorage.getItem('token'));
    formData.append('file', file, file.name);

    dispatch(sendFileAsyncThunk({ file: file, data: formData }));
    dispatch(
      chatbotSliceActions.addMessage({
        userMessage: true,
        custom: 'file',
        data: { fileName: file.name, fileSize: file.size, fileType: file.type },
        datetime: getGeorgianDateTime()
      })
    );
  };

  const handleUploadFile = () => {
    if (!loading) {
      uploadFileRef.current.click();
    }
  };

  const handleVoiceRecording = async () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  return (
    <div style={{ display: isOpen ? 'block' : 'none' }}>
      <div className='u2g-chat-window-header'>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 10 }}>
            <ChatBotIcon fill='#fff' width={28} />
          </div>
          <div>{t('_CHATBOT_HEADER')}</div>
        </div>
        <div
          className='u2g-chat-window-header-close'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <CloseModalWhiteIcon width={20} height={20} />
        </div>
      </div>
      <div className='u2g-chat-window-body'>
        <div className='u2g-chat-messages' ref={messageListRef}>
          {messages.map((element, index) => {
            return (
              <ChatMessage
                custom={element.custom}
                data={element.data}
                key={index}
                sender={element.sender}
                message={element.message}
                datetime={element.datetime}
                userMessage={element.userMessage}
                buttons={element.buttons}
                scrollDown={scrollDown}
              />
            );
          })}
          {loading && (
            <div style={{ width: 40, height: 10, marginLeft: 50 }}>
              <ChatLoading2Icon fill='#f68220' />
            </div>
          )}
          {/* <audio src={blobURL} controls='controls' /> */}
          {isRecording ? (
            <AudioWaveIcon
              fill={loading ? '#ccc' : '#f68220'}
              width={30}
              cursor={loading ? 'normal' : 'pointer'}
              display={context === 'package-info' || context === 'upload-purchase-order' ? 'none' : ''}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={`u2g-chat-input ${loading && 'u2g-chat-input-disabled'}`}>
        <input type={'file'} style={{ display: 'none' }} ref={uploadFileRef} onChange={handleFileSelected} />
        <input disabled={loading} value={messageInput} onChange={(e) => setMessageInput(e.currentTarget.value)} onKeyUp={handleKeyUp} />
        {messageInput.length > 0 ? (
          <SendRightIcon fill={loading ? '#ccc' : '#f68220'} width={23} cursor={loading ? 'normal' : 'pointer'} onClick={sentMessageFromInput} />
        ) : (
          <>
            <SendRightIcon
              fill={loading ? '#ccc' : '#f68220'}
              width={23}
              cursor={loading ? 'normal' : 'pointer'}
              onClick={sentMessageFromInput}
              display={context === 'package-info' || context === 'upload-purchase-order' ? 'none' : ''}
            />
            <ImageUploadIcon
              fill={loading ? '#ccc' : '#f68220'}
              width={30}
              cursor={loading ? 'normal' : 'pointer'}
              onClick={handleUploadFile}
              display={context === 'package-info' || context === 'upload-purchase-order' ? '' : 'none'}
            />
          </>
        )}
        {false && (
          <div style={{ marginLeft: 5, width: 30 }}>
            {isRecording ? (
              <StopIcon
                fill={loading ? '#ccc' : '#f68220'}
                width={23}
                cursor={loading ? 'normal' : 'pointer'}
                onClick={handleVoiceRecording}
                display={context === 'package-info' || context === 'upload-purchase-order' ? 'none' : ''}
              />
            ) : (
              <MicrophoneIcon
                fill={loading ? '#ccc' : '#f68220'}
                width={18}
                cursor={loading ? 'normal' : 'pointer'}
                onClick={handleVoiceRecording}
                display={context === 'package-info' || context === 'upload-purchase-order' ? 'none' : ''}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
